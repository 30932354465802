import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface NavLinkProps {
  href: string;
  text: string;
}

const NavbarToggle: React.FC<{ menuOpen: boolean; onClick: () => void }> = ({
  menuOpen,
  onClick,
}) => (
  <button
    onClick={onClick}
    className="focus:shadow-2xl focus:shadow-black/5 mobile-menu-button inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg transition-all duration-300 ease-in-out lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    aria-controls="navbar-default"
    aria-expanded={menuOpen ? 'true' : 'false'}
  >
    <span className="sr-only">Open main menu</span>
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  </button>
);

const NavLink: React.FC<NavLinkProps> = ({ href, text }) => (
  <li className="nav-item">
    <a href={href} className="nav-link text-white hover:text-yellow-500">
      {text}
    </a>
  </li>
);

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const src = require('../../assets/images/250x75 Transparent 9.png');
  const { t } = useTranslation();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed pl-0 w-full z-20 bg-blue-950"
      id="nav-main"
    >
      <div className="container flex flex-wrap items-center justify-between mx-auto px-4 sm:px-4 lg:px-6 w-full lg:bg-transparent bg-blue-950">
        <a href="/" className="flex items-center">
          <img src={src} alt="nav Logo" />
          <span className=""></span>
        </a>
        <NavbarToggle menuOpen={menuOpen} onClick={toggleMenu} />
        <div
          className={`mobile-menu ${menuOpen ? '' : 'hidden'} w-full lg:block lg:w-auto`}
          id="navbar-default"
        >
          <ul className="nav navbar-nav flex items-center flex-col rounded-lg lg:flex-row lg:py-0 py-6 lg:gap-y-0 gap-y-2 text-center lg:shadow-none shadow-md">
            <NavLink href="#Home" text={t('nav-home')} />
            <NavLink href="#Services" text={t('nav-services')} />
            <NavLink href="#aboutUs" text={t('nav-aboutUs')} />
            <NavLink href="#contactUs" text={t('nav-contactUs')} />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
