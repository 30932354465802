import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import './assets/styles/output.css';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import { useEffect } from 'react';
import { setAnalytics } from './analytics/services/AnalyticsService';
import googleAnalytics from './analytics/adapters/GoogleAnalytics';
import { useLocation } from 'react-router-dom';

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import language detector

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector) // Use language detector
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });

googleAnalytics.initialize();
setAnalytics(googleAnalytics);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
