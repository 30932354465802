// googleAnalytics.ts
import ReactGA from 'react-ga4';
import Analytics from '../types/Analytics';
import generateClientIdGa from '../../utils/GenerateClientId';
import { UaEventOptions } from 'react-ga4/types/ga4';
import Config from '../../config/Config';
enum HitTypes {
  PageView = 'pageview',
}

class GoogleAnalytics implements Analytics {
  initialize(): void {
    const trackingId = Config.gaTrackingId;
    if (trackingId) {
      ReactGA.initialize([
        {
          trackingId,
          gaOptions: {
            anonymizeIp: true,
            clientId: generateClientIdGa(),
            siteSpeedSampleRate: 100,
          },
        },
      ]);
    } else {
      throw new Error('Google Analytics tracking ID is missing');
    }
  }

  pageview(path: string): void {
    this.sendData(HitTypes.PageView, { page: path });
  }

  event(options: { category: string; action: string; label?: string; value?: number }): void {
    ReactGA.event(options);
  }

  sendData(type: HitTypes, data: Object) {
    ReactGA.send({ hitType: type, ...data });
  }

  trackEventBuilder(categoryName: string) {
    return (options: Omit<UaEventOptions, 'category'>) => {
      this.event({ category: categoryName, ...options });
    };
  }
}
const googleAnalytics = new GoogleAnalytics();
export default googleAnalytics;
// analyticsService.ts
