import React from 'react';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className="lg:py-40 py-20" id="Services">
        <div className="container w-auto mx-auto relative px-4 sm:px-4 lg:px-6">
          <div className="grid gap-9">
            <div className="lg:w-[833px] w-auto flex items-start  space-x-4 lg:pb-10 pb-10">
              <div className="w-28 border-b border-[#0f0a0a] mt-5 lg:block hidden max-[1024px]:!hidden"></div>
              <h3 className="text-zinc-800 xl:text-6xl lg:text-4xl text-3xl font-normal font-brinnan lg:pl-9 lg:text-left text-center">
                {t('services-section-title')}
              </h3>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-20">
              {/* Repeat the following structure for each service */}
              <ServiceCard
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 40 40"
                    id="ai"
                  >
                    <g
                      fill="#222"
                      data-name="Ai"
                      transform="matrix(0.963643,-0.00885508,0.0090181,0.94622359,-11.271924,-9.8177783)"
                    >
                      <path
                        d="M 52.68,54.18 H 11.32 a 1.5,1.5 0 0 1 -1.5,-1.5 V 11.32 a 1.5,1.5 0 0 1 1.5,-1.5 h 41.36 a 1.5,1.5 0 0 1 1.5,1.5 v 41.36 a 1.5,1.5 0 0 1 -1.5,1.5 z m -39.86,-3 H 51.18 V 12.82 H 12.82 Z"
                        id="path1"
                      />
                      <path
                        fill="currentColor"
                        d="m 34.79,32.45 -0.87,-5.32 a 5.49,5.49 0 0 0 -10.84,0 l -0.9,5.55 -1.18,7.08 a 1.5190787,1.5190787 0 1 0 3,0.48 l 0.94,-5.78 0.49,-3 0.57,-3.87 a 2.49,2.49 0 0 1 4.92,0 l 0.63,3.87 0.49,3 0.96,5.78 a 1.5,1.5 0 0 0 1.5,1.26 h 0.24 A 1.5,1.5 0 0 0 36,39.76 L 34.88,33 Z"
                        id="path2"
                      />
                      <path
                        fill="currentColor"
                        d="m 34.88,33 a 1.49,1.49 0 0 1 -1.49,1.41 h -9.74 a 1.5,1.5 0 0 1 -1.5,-1.5 1.53,1.53 0 0 1 0,-0.3 1.49,1.49 0 0 1 1.47,-1.2 h 9.74 a 1.49,1.49 0 0 1 1.4,1 z m 6.62,8.5 A 1.5,1.5 0 0 1 40,40 V 29 a 1.5,1.5 0 0 1 3,0 v 11 a 1.5,1.5 0 0 1 -1.5,1.5 z m 0,-15.19 A 1.56,1.56 0 0 1 40.93,26.2 1.51,1.51 0 0 1 40.44,25.87 1.63,1.63 0 0 1 40.11,25.38 1.56,1.56 0 0 1 40,24.81 a 1.5,1.5 0 0 1 0.44,-1.06 1.45,1.45 0 0 1 0.23,-0.19 l 0.26,-0.14 0.28,-0.08 a 1.42,1.42 0 0 1 0.58,0 l 0.28,0.08 0.26,0.14 a 2.11,2.11 0 0 1 0.23,0.19 1.5,1.5 0 0 1 0.44,1.06 1.56,1.56 0 0 1 -0.11,0.57 1.63,1.63 0 0 1 -0.33,0.49 1.51,1.51 0 0 1 -0.49,0.33 1.56,1.56 0 0 1 -0.57,0.11 z"
                        id="path3"
                      />
                    </g>
                  </svg>
                }
                title="ai-workflow-integration"
                description="ai-workflow-integration-summary"
              />
              <ServiceCard
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="custom">
                    <path
                      d="M24 0C10.751 0 0 10.749 0 23.998 0 37.248 10.751 48 24 48s24-10.753 24-24.002C48 10.749 37.249 0 24 0zm0 1.008c12.704 0 22.992 10.285 22.992 22.99 0 12.705-10.288 22.994-22.992 22.994S1.008 36.702 1.008 23.998c0-12.705 10.288-22.99 22.992-22.99zM11.5 14l-.5.5V16h1v-1h1v-1h-1.5zm2.5 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h1v1h1v-1.5l-.5-.5H35zm-7.486 2.965a.5.5 0 0 0-.045.004.5.5 0 0 0-.125.031.5.5 0 0 0-.282.25l-7 11.969a.504.504 0 1 0 .875.5l7-11.969a.5.5 0 0 0-.423-.785zM11 17v2h1v-2h-1zm25 0v2h1v-2h-1zm-17.5 2a.486.486 0 0 0-.344.156l-3.843 3.875c-.06.024-.108.076-.157.125a.476.476 0 0 0 0 .688c.05.049.096.1.156.125l3.844 3.875a.476.476 0 0 0 .688 0 .476.476 0 0 0 0-.688L15.187 23.5l3.657-3.656a.476.476 0 0 0 0-.688A.487.487 0 0 0 18.5 19zm11 0a.485.485 0 0 0-.344.156.476.476 0 0 0 0 .688l3.657 3.656-3.657 3.656a.476.476 0 0 0 0 .688.476.476 0 0 0 .688 0l3.843-3.875c.06-.024.108-.076.157-.125a.476.476 0 0 0 0-.688c-.05-.049-.096-.1-.157-.125l-3.843-3.875A.487.487 0 0 0 29.5 19zM11 20v2h1v-2h-1zm25 0v2h1v-2h-1zm-25 3v2h1v-2h-1zm25 0v2h1v-2h-1zm-25 3v2h1v-2h-1zm25 0v2h1v-2h-1zm-25 3v2h1v-2h-1zm25 0v2h1v-2h-1zm-25 3v.5l.5.5H13v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h2v-1h-2zm3 0v1h1.5l.5-.5V32h-2z"
                      color="#000"
                      overflow="visible"
                    ></path>
                  </svg>
                }
                title="custom-software-solutions"
                description="custom-software-solutions-summary"
              />
              <ServiceCard
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="badge">
                    <path
                      //   style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal"
                      d="M24 0C10.751 0 0 10.751 0 24s10.751 24 24 24 24-10.751 24-24S37.249 0 24 0zm0 1c12.708 0 23 10.292 23 23 0 12.709-10.292 23-23 23S1 36.709 1 24C1 11.292 11.292 1 24 1zm.098 10.203c-4.405 0-8 3.49-8 7.799 0 2.13.88 4.056 2.3 5.463l-2.382 9.473a.5.5 0 0 0 .73.558l2.945-1.656 1.729 2.914a.5.5 0 0 0 .916-.133l1.713-6.805 1.713 6.805a.5.5 0 0 0 .916.133l1.728-2.914 2.946 1.656a.5.5 0 0 0 .73-.559l-2.363-9.394c1.467-1.413 2.379-3.372 2.379-5.541 0-4.309-3.595-7.799-8-7.799zm0 1c3.879 0 7 3.046 7 6.799 0 3.753-3.121 6.799-7 6.799-3.88 0-7-3.046-7-6.799 0-3.753 3.12-6.799 7-6.799zm-.03 3.645a.507.493 0 0 0-.386.209l-1.034 1.427-1.716.518a.507.493 0 0 0-.256.764l1.074 1.4-.025 1.746a.507.493 0 0 0 .523.498.507.493 0 0 0 .148-.027l1.702-.563 1.699.563a.507.493 0 0 0 .672-.47l-.024-1.747 1.075-1.4a.507.493 0 0 0-.258-.764l-1.715-.518-1.035-1.427a.507.493 0 0 0-.444-.21zm.03 1.347.72.992a.507.493 0 0 0 .264.188l1.195.361-.75.973a.507.493 0 0 0-.101.3l.02 1.216-1.184-.391a.507.493 0 0 0-.328 0l-1.184.39.02-1.214a.507.493 0 0 0-.104-.301l-.75-.973 1.197-.361a.507.493 0 0 0 .262-.188l.723-.992zm-4.85 7.994a8.07 8.07 0 0 0 4.283 1.582L21.662 34.2l-1.36-2.289a.5.5 0 0 0-.673-.181l-2.361 1.326 1.98-7.866zm9.617.061 1.965 7.805-2.361-1.326a.5.5 0 0 0-.674.181l-1.36 2.29-1.867-7.423a8.077 8.077 0 0 0 4.297-1.527z"
                      color="#000"
                      fontFamily="sans-serif"
                      fontWeight="400"
                      overflow="visible"
                    ></path>
                  </svg>
                }
                title="quality-assurance"
                description="quality-assurance-summary"
              />
              <ServiceCard
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="badge">
                    <path
                      //   style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal"
                      d="M24 0C10.751 0 0 10.751 0 24s10.751 24 24 24 24-10.751 24-24S37.249 0 24 0zm0 1c12.708 0 23 10.292 23 23 0 12.709-10.292 23-23 23S1 36.709 1 24C1 11.292 11.292 1 24 1zm.098 10.203c-4.405 0-8 3.49-8 7.799 0 2.13.88 4.056 2.3 5.463l-2.382 9.473a.5.5 0 0 0 .73.558l2.945-1.656 1.729 2.914a.5.5 0 0 0 .916-.133l1.713-6.805 1.713 6.805a.5.5 0 0 0 .916.133l1.728-2.914 2.946 1.656a.5.5 0 0 0 .73-.559l-2.363-9.394c1.467-1.413 2.379-3.372 2.379-5.541 0-4.309-3.595-7.799-8-7.799zm0 1c3.879 0 7 3.046 7 6.799 0 3.753-3.121 6.799-7 6.799-3.88 0-7-3.046-7-6.799 0-3.753 3.12-6.799 7-6.799zm-.03 3.645a.507.493 0 0 0-.386.209l-1.034 1.427-1.716.518a.507.493 0 0 0-.256.764l1.074 1.4-.025 1.746a.507.493 0 0 0 .523.498.507.493 0 0 0 .148-.027l1.702-.563 1.699.563a.507.493 0 0 0 .672-.47l-.024-1.747 1.075-1.4a.507.493 0 0 0-.258-.764l-1.715-.518-1.035-1.427a.507.493 0 0 0-.444-.21zm.03 1.347.72.992a.507.493 0 0 0 .264.188l1.195.361-.75.973a.507.493 0 0 0-.101.3l.02 1.216-1.184-.391a.507.493 0 0 0-.328 0l-1.184.39.02-1.214a.507.493 0 0 0-.104-.301l-.75-.973 1.197-.361a.507.493 0 0 0 .262-.188l.723-.992zm-4.85 7.994a8.07 8.07 0 0 0 4.283 1.582L21.662 34.2l-1.36-2.289a.5.5 0 0 0-.673-.181l-2.361 1.326 1.98-7.866zm9.617.061 1.965 7.805-2.361-1.326a.5.5 0 0 0-.674.181l-1.36 2.29-1.867-7.423a8.077 8.077 0 0 0 4.297-1.527z"
                      color="#000"
                      fontFamily="sans-serif"
                      fontWeight="400"
                      overflow="visible"
                    ></path>
                  </svg>
                }
                title="quality-audit"
                description="quality-audit-summary"
              />
              {/* Repeat for other services */}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-zinc-900/20"></div>
    </section>
  );
};

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon, title, description }) => {
  const { t } = useTranslation();
  return (
    <div className="group">
      <div className="w-fit p-4 mx-auto border border-zinc-800 group-hover:bg-indigo-500 transition-all duration-700 ease-in-out group-hover:border-transparent">
        {icon}
      </div>
      <div className="pt-7 shadow-lg">
        <h4 className="text-center text-zinc-800 text-3xl font-light font-brinnan pb-4">
          {t(title)}
        </h4>
        <p
          className="w-81 mx-auto text-left text-zinc-800/85  font-normal leading-6 text-base font-texta  p-4  overflow-hidden"
          dangerouslySetInnerHTML={{ __html: t(description) }}
        ></p>
      </div>
    </div>
  );
};

export default ServiceSection;
