import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const src = require('../../assets/images/heroImg.png');
  const { t } = useTranslation();
  return (
    <div className="lg:pt-20 pt-32 relative h-auto xl:h-screen max-[1366px]:!h-full" id="Home">
      <div className="max-w-full lg:max-w-[680px] xl:max-w-2xl left-0 top-auto lg:top-[170px] xl:top-[200px] mx-4 lg:mx-0 lg:absolute bg-zinc-800 lg:p-10 p-10 z-10">
        <h1
          className="text-white lg:text-7xl text-5xl font-normal pb-7 font-brinnan"
          dangerouslySetInnerHTML={{ __html: t('services-title') }}
        ></h1>
        <p className="opacity-50 text-white text-lg font-semibold leading-loose">
          {t('services-summary')}
        </p>
      </div>
      <div className="container w-auto h-100 mx-auto relative px-4 sm:px-4 lg:px-6">
        <div className="relative h-100 lg:flex justify-end">
          <img className="relative h-full" src={src} alt="Hero " />
          <div className="absolute -bottom-10 hidden xl:block xl:-left-10 2xl:left-56 -z-10 max-[1366px]:!hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
              fill="none"
            ></svg>
          </div>
          <a
            href="#Services"
            className="absolute -bottom-14 -right-14 z-10 down w-[120px] h-[120px]  bg-blue-950 items-center justify-center xl:flex hidden max-[1366px]:!hidden page-scroll"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
            >
              <path
                d="M23.4004 40.9255L36.0004 52.2002M36.0004 52.2002L48.6004 40.9255M36.0004 52.2002L36.0004 19.8002"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
          {/* <div className="lg:absolute top-1/3 xl:-right-[70px] 2xl:-right-[120px] gap-6 py-10 xl:py-0 2xl:grid hidden items-center justify-center">
            <a href="/" className="rounded-2xl flex-col justify-start items-start inline-flex">
              <svg
                className="text-[#1F232E] hover:text-indigo-500 transition-all duration-200"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g id="Social Media">
                  <path
                    id="Logo"
                    d="M19.3435 16.1003L19.887 12.6497H16.5386V10.4068C16.5386 9.4633 17.0061 8.54134 18.5009 8.54134H20.0447V5.60293C19.1457 5.4597 18.2373 5.38221 17.3268 5.37109C14.5709 5.37109 12.7717 7.02631 12.7717 10.0186V12.6497H9.7168V16.1003H12.7717V24.4465H16.5386V16.1003H19.3435Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </a> */}
          {/* Add similar components for other social media icons */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
