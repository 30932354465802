import React from 'react';

const FooterSection: React.FC = () => {
  const src = require('../../assets/images/250x75 Transparent 9.png');
  return (
    <div className="bg-blue-950 py-5">
      <div className="container w-auto mx-auto relative px-4 sm:px-4 lg:px-6">
        <div className="items-center lg:flex grid lg:justify-between justify-center gap-4">
          {/* <p className="text-white/50 text-sm font-normal font-brinnan">
            © All Right Reserved to IT consulting 2022
          </p> */}
          <img src={src} className="" alt="nav Logo" style={{ width: '250px', height: '75px' }} />
          <div className="flex gap-2.5 lg:justify-start justify-center">
            {/* <a
              href="#!"
              className="p-2 border border-white/10 hover:border-white/50 transition-all duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.1297 18.3332V10.7307H13.6816L14.0636 7.76794H11.1297V5.87627C11.1297 5.01849 11.368 4.43386 12.5981 4.43386L14.1671 4.43317V1.78328C13.4077 1.70292 12.6445 1.66399 11.8809 1.66666C9.61883 1.66666 8.07018 3.04735 8.07018 5.58303V7.76803H5.51172V10.7308H8.0701V18.3333L11.1297 18.3332Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="#!"
              className="p-2 border border-white/10 hover:border-white/50 transition-all duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3327 4.82996C17.7087 5.10666 17.0468 5.2882 16.3689 5.36855C17.0829 4.94133 17.6173 4.26907 17.8723 3.47706C17.2011 3.8757 16.4666 4.15633 15.7006 4.30678C15.2314 3.80698 14.6228 3.45972 13.9537 3.31005C13.2847 3.16038 12.5862 3.2152 11.9487 3.4674C11.3112 3.7196 10.7642 4.15754 10.3786 4.72442C9.99308 5.2913 9.78677 5.96095 9.78647 6.64651C9.78618 6.9088 9.81573 7.17026 9.87455 7.42587C8.51511 7.35775 7.1852 7.00451 5.97112 6.38909C4.75705 5.77367 3.68596 4.90983 2.82738 3.85364C2.39019 4.60634 2.25624 5.49735 2.45278 6.34534C2.64933 7.19332 3.1616 7.93455 3.88535 8.41817C3.34234 8.40135 2.81124 8.25479 2.33647 7.99073V8.03394C2.33657 8.82336 2.60972 9.58844 3.10959 10.1994C3.60945 10.8104 4.30527 11.2297 5.07902 11.3862C4.78546 11.4663 4.48248 11.5066 4.17819 11.5061C3.96225 11.5061 3.74677 11.4859 3.53457 11.4459C3.75308 12.1251 4.17834 12.719 4.75091 13.1447C5.32347 13.5704 6.01474 13.8065 6.72807 13.8201C5.29431 14.9428 3.47415 15.4517 1.66602 15.2355C3.2292 16.2404 5.0487 16.7739 6.90705 16.7723C8.18698 16.7811 9.45591 16.5355 10.6401 16.0497C11.8243 15.564 12.9002 14.8478 13.8053 13.9427C14.7104 13.0376 15.4266 11.9617 15.9123 10.7775C16.3981 9.59331 16.6437 8.32438 16.6349 7.04445C16.6349 6.89611 16.6314 6.7487 16.6245 6.6022C17.2946 6.11753 17.873 5.51742 18.3327 4.82996Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="#!"
              className="p-2 border border-white/10 hover:border-white/50 transition-all duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.8244 3C6.96147 3 5 5.72823 5 8.01571C5 8.44119 5.04474 8.85167 5.14915 9.22401C5.38037 10.0827 5.88003 10.7743 6.67064 11.0937C6.85695 11.1771 7.02844 11.0937 7.07318 10.8961C7.11809 10.7516 7.19976 10.4019 7.24478 10.25C7.30446 10.0447 7.282 9.97651 7.13285 9.80164C6.93907 9.56591 6.77493 9.29991 6.68551 8.96556C6.62578 8.74516 6.58845 8.50199 6.58845 8.2209C6.58845 6.19174 8.13211 4.36778 10.6082 4.36778C12.7932 4.36778 14.0015 5.68252 14.0015 7.44574C14.0015 7.97029 13.9492 8.46418 13.8522 8.93525C13.5093 10.554 12.6144 11.7244 11.4136 11.7244C10.5634 11.7244 9.91457 11.0325 10.1235 10.1739C10.2429 9.69547 10.4142 9.19363 10.5635 8.70724C10.7201 8.19796 10.8543 7.70413 10.8543 7.32419C10.8543 6.65537 10.4964 6.11589 9.7433 6.11589C8.87046 6.11589 8.16961 7.01266 8.16961 8.1979C8.16961 8.41848 8.19167 8.63127 8.22165 8.79069C8.30372 9.2164 8.43058 9.47478 8.43058 9.47478C8.43058 9.47478 7.53573 13.2062 7.38641 13.8599C7.07324 15.1668 7.34167 16.755 7.36418 16.9147C7.37165 17.0057 7.49823 17.0287 7.55813 16.9602C7.64003 16.8542 8.67651 15.5998 9.03459 14.3309C9.12407 13.9661 9.60897 12.1119 9.60897 12.1119C9.88498 12.6517 10.7126 13.1226 11.6 13.1226C13.7252 13.1226 15.3065 11.5495 15.821 9.26205C15.9403 8.74516 16 8.18274 16 7.6054C16 5.2114 13.9492 3 10.8244 3Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="#!"
              className="p-2 border border-white/10 hover:border-white/50 transition-all duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.16966 5.83334H4.14619C3.89067 5.84875 3.63472 5.81132 3.39432 5.72338C3.15392 5.63544 2.93423 5.49887 2.74897 5.32222C2.56372 5.14557 2.41687 4.93262 2.3176 4.69667C2.21833 4.46072 2.16878 4.20684 2.17203 3.95088C2.17527 3.69491 2.23126 3.44237 2.33649 3.20902C2.44171 2.97566 2.59392 2.76651 2.7836 2.59462C2.97328 2.42272 3.19637 2.29178 3.43892 2.20997C3.68148 2.12816 3.9383 2.09724 4.19334 2.11913C4.44965 2.10056 4.70702 2.13523 4.94929 2.22095C5.19155 2.30666 5.41345 2.44158 5.60106 2.6172C5.78866 2.79282 5.93789 3.00536 6.03938 3.24145C6.14087 3.47754 6.19241 3.73207 6.19078 3.98905C6.18914 4.24602 6.13435 4.49988 6.02986 4.73465C5.92537 4.96943 5.77343 5.18005 5.58361 5.35326C5.39378 5.52648 5.17017 5.65855 4.92684 5.74117C4.6835 5.82379 4.42571 5.85517 4.16966 5.83334Z"
                  fill="white"
                />
                <path d="M5.84896 8.3335H2.51562V18.3335H5.84896V8.3335Z" fill="white" />
                <path
                  d="M14.5977 8.3335C14.036 8.33503 13.4819 8.4635 12.9768 8.70932C12.4717 8.95513 12.0288 9.31192 11.681 9.75302V8.3335H8.34766V18.3335H11.681V13.7502C11.681 13.3081 11.8566 12.8842 12.1691 12.5717C12.4817 12.2591 12.9056 12.0835 13.3477 12.0835C13.7897 12.0835 14.2136 12.2591 14.5262 12.5717C14.8387 12.8842 15.0143 13.3081 15.0143 13.7502V18.3335H18.3477V12.0835C18.3477 11.591 18.2507 11.1034 18.0622 10.6484C17.8737 10.1935 17.5975 9.78007 17.2493 9.43185C16.9011 9.08363 16.4877 8.8074 16.0327 8.61895C15.5777 8.43049 15.0901 8.3335 14.5977 8.3335Z"
                  fill="white"
                />
              </svg>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
