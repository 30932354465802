// analyticsService.ts
import Analytics from '../types/Analytics';
import GoogleAnalytics from '../adapters/GoogleAnalytics';

let analytics: Analytics = GoogleAnalytics;

export const setAnalytics = (newAnalytics: Analytics): void => {
  analytics = newAnalytics;
};

export const getAnalytics = (): Analytics => {
  return analytics;
};
