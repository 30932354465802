import EnviromentConfig from './EnvironmentConfig';

class Config implements EnviromentConfig {
  apiUrl = process.env['REACT_APP_JMB_SOL_API']?.toString() || '';
  recaptchaSiteKey = process.env['REACT_APP_RECAPTCHASITE_KEY']?.toString() || '';
  gaTrackingId = process.env['REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID']?.toString() || '';
}
// create a new instance of Config
const config = new Config();
export default config;
