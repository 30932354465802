import React, { useRef, useState } from 'react';
import { Form } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FieldError, SubmitHandler } from 'react-hook-form';
import { ValidationError } from './ValidationError';
import axios from 'axios';
import Config from '../../config/Config';
import ReCAPTCHA from 'react-google-recaptcha';
import { getAnalytics } from '../../analytics/services/AnalyticsService';

type Contact = {
  firstName: string;
  lastName: string;
  company: string;
  jobTitle: string;
  email: string;
  phone: string;
  inquiryType: string;
  message: string;
};
const ContactUsSection: React.FC = () => {
  const analytics = getAnalytics();
  const src = require('../../assets/images/evaluation-img.png');
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false); // State to track reCAPTCHA completion
  const recaptcha = useRef<ReCAPTCHA>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const fieldStyle = 'flex flex-col mb-4';

  const styleEditor = (fieldError: FieldError | undefined) => {
    return fieldError
      ? 'bg-indigo-500/10 border border-red-500 focus:border-indigo-500 outline-none text-zinc-800 focus-within:text-zinc-900 text-base font-normal font-brinnan py-2 pl-2'
      : 'bg-indigo-500/10 border border-transparent focus:border-indigo-500 outline-none text-zinc-800 focus-within:text-zinc-900 text-base font-normal font-brinnan py-2 pl-2';
  };

  const onSubmit: SubmitHandler<Contact> = async (data: Contact) => {
    analytics.event({
      category: 'User Interaction',
      action: 'Clicked Button',
      label: 'Contact button clicked',
    });
    try {
      const captchaValue = recaptcha.current?.getValue();
      // Ensure that reCAPTCHA has been completed before submitting the form
      if (!captchaCompleted) {
        console.log('Please complete the reCAPTCHA.');
        return;
      }
      const res = await axios.post(Config.apiUrl + 'validate-captcha', captchaValue);
      console.log('reCAPTCHA validation response: ', res.data);
      if (!res.data) {
        console.log('reCAPTCHA validation failed.');
        recaptcha.current?.reset(); // Fix: Access the current value of the recaptcha ref and call the reset method on it
        return;
      }
      await axios.post(Config.apiUrl + 'contact', data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const closeThankYouMessage = () => {
    setSubmitted(false);
    recaptcha.current?.reset();
    setCaptchaCompleted(false);
    reset();
  };

  const handleRecaptchaChange = (response: string | null) => {
    // Set captchaCompleted state to true when reCAPTCHA is completed
    setCaptchaCompleted(response !== null);
  };

  return (
    <section>
      <div className="lg:pb-25 pb-20 xl:w-auto lg:pt-20 pt-14" id="contactUs">
        <div className="container w-auto mx-auto relative px-4 sm:px-4 lg:px-6">
          <div className="lg:grid grid-cols-2 xl:gap-36 lg:gap-16 items-center">
            <div className="xl:w-auto lg:pt-20 pt-14">
              <div className="lg:w-[550px] w-auto flex items-center lg:justify-start justify-center pb-6 gap-8">
                <div className="w-28 border-b border-[#0F0A0A] mt-4 lg:block hidden max-[1024px]:!hidden"></div>
                <h3 className="text-zinc-800 xl:text-6xl lg:text-4xl text-3xl font-normal font-brinnan">
                  {t('contact-us-section-title')}
                </h3>
              </div>
              <div className="text-center lg:pt-1">
                <p className="text-zinc-800  font-normal font-texta align-center">
                  We’d love to partner with You!
                </p>
              </div>
              <div className="relative h-full">
                <Form className="space-y-4" noValidate onSubmit={handleSubmit(onSubmit)}>
                  <div className={fieldStyle}>
                    <input
                      type="text"
                      id="firstName"
                      {...register('firstName', { required: 'You must enter your First Name' })}
                      placeholder="First Name"
                      className={styleEditor(errors.firstName)}
                    />
                    <ValidationError fieldError={errors.firstName} />
                  </div>
                  <div className={fieldStyle}>
                    <input
                      type="text"
                      id="lastName"
                      {...register('lastName', { required: 'You must enter your Last Name' })}
                      placeholder="Last Name"
                      className={styleEditor(errors.lastName)}
                    />
                  </div>
                  <div className={fieldStyle}>
                    <input
                      type="text"
                      id="company"
                      {...register('company', { required: 'You must enter your Company name' })}
                      placeholder="Company"
                      className={styleEditor(errors.company)}
                    />
                  </div>
                  <div className={fieldStyle}>
                    <input
                      type="text"
                      id="jotTitle"
                      {...register('jobTitle', { required: 'You must enter your Job Title' })}
                      placeholder="Job Title"
                      className={styleEditor(errors.jobTitle)}
                    />
                  </div>
                  <div className={fieldStyle}>
                    <input
                      type="email"
                      id="email"
                      {...register('email', {
                        required: 'You must enter your email',
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Entered value does not match email format',
                        },
                      })}
                      placeholder="Email"
                      className={styleEditor(errors.email)}
                    />
                    <ValidationError fieldError={errors.email} />
                  </div>
                  <div className={fieldStyle}>
                    <input
                      type="text"
                      id="phone"
                      {...register('phone', { required: 'You must enter your phone number' })}
                      placeholder="Phone"
                      className={styleEditor(errors.phone)}
                    />
                  </div>
                  <div className={`${fieldStyle} relative w-full`}>
                    <select
                      {...register('inquiryType')}
                      id="inquiryType"
                      className={styleEditor(errors.inquiryType)}
                    >
                      <option value="general">General Inquiry</option>
                      <option value="partnership">Partnership Inquiry</option>
                      <option value="evaluation">Free Evaluation Inquiry</option>
                    </select>
                    <ValidationError fieldError={errors.inquiryType} />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 13.293a1 1 0 001.414 0l4-4a1 1 0 10-1.414-1.414L10 11.586l-3.293-3.293a1 1 0 10-1.414 1.414l4 4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={fieldStyle}>
                    <textarea
                      id="message"
                      {...register('message', { required: 'You must enter your message' })}
                      placeholder="Message"
                      className={styleEditor(errors.message)}
                    />
                  </div>
                  <div className={`${fieldStyle} bg-blue-950`}>
                    <button
                      type="submit"
                      className="text-white text-base font-normal font-brinnan transition-all duration-300 ease-in-out bg-indigo-600 hover:bg-indigo-800 py-5 justify-center items-center gap-2.5 inline-flex"
                    >
                      Contact Us
                    </button>
                  </div>
                  <div className="flex items-center justify-center">
                    <div
                      id="recaptcha"
                      className="w-full max-w-xs bg-gray-100 rounded-lg shadow-md p-4"
                    >
                      <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={Config.recaptchaSiteKey}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                  </div>
                </Form>
                {/* Thank you message */}
                {submitted && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="p-4 bg-white rounded shadow relative">
                      <button
                        onClick={closeThankYouMessage}
                        className="absolute top-0 right-0  text-red-600 hover:text-red-800 font-bold w-8 h-8 sm:w-5 sm:h-0"
                      >
                        X
                      </button>
                      <p>
                        Thank you for your message! <br />
                        We will get back to you within 24hrs
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="xl:col-span-5 col-span-10 bg-white shadow-2xl lg:translate-y-40 translate-y-0 xl:-translate-x-10 lg:-translate-x-10 lg:mb-44">
                <div className="lg:pt-12 flex items-start gap-6">
                  <img
                    className="relative h-full"
                    src={src}
                    alt="Hero "
                    style={{ maxWidth: '80%', maxHeight: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
