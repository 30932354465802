import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../App';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/about',
        element: <div>About</div>,
      },
      {
        path: '/contact',
        element: <div>Contact</div>,
      },
    ],
  },
]);

export default function Routes() {
  return <RouterProvider router={router} />;
}
