// App.tsx
import React from 'react';
import Header from './components/Header/Header';
import HeroSection from './components/sections/HeroSection ';
import ServiceSection from './components/sections/ServiceSection';
import AboutUsSection from './components/sections/AboutUsSection';
import ContactUsSection from './components/sections/ContactUsSection';
import FooterSection from './components/sections/FooterSection';

import { useEffect } from 'react';
import googleAnalytics from './analytics/adapters/GoogleAnalytics';
import { useLocation } from 'react-router-dom';

const App: React.FC = () => {
  // Custom hook to track pageviews with React Router
  const usePageViews = () => {
    const location = useLocation();
    useEffect(() => {
      googleAnalytics.pageview(location.pathname);
    }, [location]);
  };
  return (
    usePageViews(),
    (
      <>
        <Header />
        <HeroSection />
        <ServiceSection />
        <AboutUsSection />
        <ContactUsSection />
        <FooterSection />
      </>
    )
  );
};
export default App;
