import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutUsSection: React.FC = () => {
  const src = require('../../assets/images/heroImg.png');
  const { t } = useTranslation();
  return (
    <section>
      <div className="lg:pb-25 pb-20 xl:w-auto lg:pt-10 pt-14" id="aboutUs">
        <div className="container w-auto mx-auto relative px-4 sm:px-4 lg:px-6">
          <div className="lg:grid grid-cols-2 xl:gap-36 lg:gap-16 items-center">
            <div className="lg:flex w-fit gap-10 relative pt-30">
              <img className="w-full" src={src} alt="About us" />
            </div>
            <div className="xl:w-auto lg:pt-10 pt-14">
              <div className="lg:w-[430px] w-auto flex items-center lg:justify-start justify-center pb-6 gap-8">
                <div className="w-28 border-b border-[#0F0A0A] mt-4 lg:block hidden max-[1024px]:!hidden"></div>
                <h3 className="text-zinc-800 xl:text-6xl lg:text-4xl text-3xl font-normal font-brinnan">
                  {t('aboutUs-section-title')}
                </h3>
              </div>
              <p
                className="2xl:w-[618px] w-auto text-zinc-800/80 text-lg font-light font-texta leading-loose pb-5 lg:text-left text-center"
                dangerouslySetInnerHTML={{ __html: t('aboutUs-section-summary') }}
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-zinc-900/20"></div>
    </section>
  );
};

export default AboutUsSection;
